<template>
  <v-row class="quotation-preview">
    <v-col cols="12" md="9">
      <v-card id="printArea">
        <v-card-text style="padding: 32px 36px;line-height: 1.3rem;">
          <v-row>
            <v-col cols="12" style="font-weight: 500;padding-bottom: 0;margin-bottom: 0;">
              <div style="margin-top: 30px;text-align: center;">
                <span style="color: #rgba(0, 0, 0, 0.87); font-weight: 700; font-size: 1.7rem; line-height:1.6rem;padding-left: 12px;">
                  {{ $t('Quotation.Quotation') }}
                </span>
              </div>
              <div style="width: 100%;display: flex;justify-content: end;">
                <div style="display: flex;flex-direction: column-reverse;">
                  <div>
                    {{ $t('Quotation.Date') }} :
                  </div>
                  <div>
                    {{ $t('Quotation.Code') }} :
                  </div>
                </div>
                <div style="display: flex;flex-direction: column-reverse;text-align: end;width: 100px;">
                  <div>
                    {{ Form.quot_dt }}
                  </div>
                  <div>
                    {{ Form.quot_code }}
                  </div>
                </div>
              </div>
              <div style="border:1px solid #000; margin: 10px 0 10px 0;padding-bottom: 0;"></div>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="7" style="font-weight: 500;padding-top: 0;">
              <tr>
                <td width="90" style="font-weight: 700;vertical-align: top">
                  To:
                </td>
                <td width="600">
                  {{ Form.cust_name }}
                </td>
              </tr>
              <div style="padding-left: 12px;font-size:0.8 rem;">
                {{ Form.cust_name_eng }}
              </div>

              <div style="padding-left: 12px;">
                {{ Form.address2 }}
              </div>
              <div style="padding-left: 12px;">
                {{ Form.address2_eng }}
              </div>
              <div style="padding-left: 12px;">
                {{ Form.address1 }}
              </div>
              <div style="padding-left: 12px;">
                {{ Form.address1_eng }}
              </div>
              <div style="padding-left: 12px;">
                {{ Form.manager_sei }} {{ Form.manager_mei }} ({{ Form.manager_firstname }},{{ Form.manager_lastname }})
              </div>

              <div style="padding-left: 12px;">
                {{ Form.email }}
              </div>
              <div style="padding-left: 12px;">
                {{ Form.postcode }},{{ Form.countryName }}
              </div>

              <div style="padding:12px 0 5px 12px;">
                {{ $t('Quotation.Description') }}
              </div>

              <div style="padding-top: 24px;">
                <div style="display: inline-block;font-size:1.0rem;font-weight: 800;width: 150px;">
                  <div style="margin: 10px;">
                    {{ $t('Quotation.FinalAmount') }} :
                  </div>
                </div>
                <div style="display: inline-block;font-size:1.0rem;font-weight: 800;width: 125px;">
                  <div style="margin: 10px;display: flex;justify-content:end;">
                    ￥{{ formatData(Form.quot_amount) }}
                  </div>
                </div>
              </div>
            </v-col>
            <v-col cols="5" style="flex-direction: column;padding-left: 0;">
              <v-row align="center">
                <!-- <v-col cols="3" class="py-0">
                  <img src="@/assets/images/logos/favicon.png" height="40px" style="object-fit: contain;margin: 10px 0 0 0;" />
                </v-col> -->
                <v-col cols="12" style="display: flex;justify-content:end; text-align:center;padding-bottom: 0;align-items: center;">
                  <img src="@/assets/images/logos/favicon.png" height="40px" style="object-fit: contain;margin: 0 0 0 0;" />
                  <div style="font-size:1.0rem;padding-left: 24px;font-weight: 800;">
                    {{ $t('GreenEarth.CompanyName') }}
                  </div>
                  <!-- <div>
                    〒306-0616
                  </div>
                  <div>
                    {{ $t('GreenEarth.Address') }}
                  </div>

                  <div>
                    TEL: 0297-44-4338
                  </div>
                  <div>
                    FAX: 0297-44-4335
                  </div> -->
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" style="padding-top: 0;padding-left: 0;">
                  <div style="display: flex;justify-content:end;">
                    〒306-0616
                  </div>
                  <div style="display: flex;justify-content:end;">
                    <span style="max-width: 220px; text-align: end;">{{ $t('GreenEarth.Address') }}</span>
                  </div>

                  <div style="display: flex;justify-content:end;">
                    TEL: 0297-44-4338
                  </div>
                  <div style="display: flex;justify-content:end;">
                    FAX: 0297-44-4335
                  </div>
                </v-col>
              </v-row>
              <!-- <img src="@/assets/images/logos/favicon.png" height="40px" style="object-fit: contain;margin: auto 0 10px;" /> -->
              <!-- <div style="font-size:1.0rem;">
                {{ $t('GreenEarth.CompanyName') }}
              </div>
              <div>
                〒306-0616
              </div>
              <div>
                {{ $t('GreenEarth.Address') }}
              </div>

              <div>
                TEL: 0297-44-4338
              </div>
              <div>
                FAX: 0297-44-4335
              </div> -->
            </v-col>
          </v-row>
          <!-- <table width="100%" cellpadding="0" cellspacing="0" style="font-weight: 600;">
            <tr>
              <td width="20%">
                XXX
              </td>
              <td width="30%"></td>
              <td width="20%">
                Date Issued:
              </td>
              <td width="30%"></td>
            </tr>
            <tr>
              <td width="20%">
                契約ID:
              </td>
              <td width="30%"></td>
              <td width="20%">
                Due Date:
              </td>
              <td width="30%"></td>
            </tr>
          </table> -->
          <v-simple-table class="purchased-items-table" style="margin-top: 40px;">
            <template v-slot:default>
              <thead>
                <tr>
                  <th style="text-align: left;width: 5%;">
                    No.
                  </th>
                  <th style="text-align: center;width:55%">
                    {{ $t('Quotation.ItemInfo') }}
                  </th>

                  <th style="text-align: center;width:10%;">
                    {{ $t('Quotation.Qty') }}
                  </th>
                  <th style="text-align:center;width:10%;">
                    {{ $t('Quotation.Price') }}
                  </th>
                  <th style="text-align: center;width:10%;">
                    {{ $t('Quotation.Amount') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style="text-align: left;">
                    1
                  </td>
                  <td style="text-align: left;">
                    {{ Form.item_id }} ( {{ Form.pellet_id }} ) <br />
                    <!-- <div v-if="languageOvv === 'zh'" v-html="Form.item_ovv_cn"></div>
                    <div v-if="languageOvv === 'ja'" v-html="Form.item_ovv_jp"></div>
                    <div v-if="languageOvv === 'en'" v-html="Form.item_ovv_en"></div> -->
                    <div v-html="languageOvv"></div>
                  </td>

                  <td style="text-align: right;">
                    {{ formatData(Form.quot_cnt) }}
                  </td>
                  <td style="text-align: right;">
                    {{ formatData(Form.quot_unit_price) }}
                  </td>
                  <td style="text-align: right;">
                    {{ formatData(Form.quot_cnt * Form.quot_unit_price) }}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <!-- Total -->
          <div class="tableTotal">
            <!-- <div style="line-height: 1.4rem;font-weight: 500;">
              <div>
                <div style="display: inline;">
                  納入期限:
                </div>
                <div style="display: inline;">
                  受注後１週間以内
                </div>
              </div>
              <div>
                <div style="display: inline;">
                  取引方法:
                </div>
                <div style="display: inline;">
                  別途お打ち合わせ
                </div>
              </div>
              <div>
                <div style="display: inline;">
                  有効期限:
                </div>
                <div style="display: inline;">
                  お見積もり後30日
                </div>
              </div>
            </div> -->
            <div>
              <table style="width: 185px">
                <tr>
                  <td style="padding-right: 64px;">
                    {{ $t('Quotation.Subtotal') }}:
                  </td>
                  <th style="text-align: right;">
                    {{ formatData(Form.quot_cnt * Form.quot_unit_price) }}
                  </th>
                </tr>
                <tr>
                  <td style="padding-right: 64px;">
                    {{ $t('Quotation.Discount') }}:
                  </td>
                  <th style="text-align: right;">
                    {{ formatData(Form.quot_cnt * Form.quot_unit_price - Form.quot_amount) }}
                  </th>
                </tr>
                <!-- <tr>
                  <td style="padding-right: 74px;">
                    消費税:
                  </td>
                  <th style="text-align: right;">
                    21%
                  </th>
                </tr> -->
              </table>
              <v-divider style="margin: 12px 0 12px;"></v-divider>
              <table style="width: 185px">
                <tr>
                  <td :style="totalEn()">
                    {{ $t('Quotation.Total') }}:
                  </td>
                  <th style="text-align: right;">
                    {{ formatData(Form.quot_amount) }}
                  </th>
                </tr>
              </table>
            </div>
          </div>
        </v-card-text>
        <v-divider style="margin: 0 32px 0;"></v-divider>

        <v-card-text style="padding:16px 36px;margin-top:auto;">
          <p style="margin-bottom: 0px;">
            <span class="font-weight-semibold">
              {{ $t('Quotation.Note') }}:
            </span>
          </p>
          <div style="line-height: 1.4rem;font-weight: 500;">
            <div>
              <div style="display: inline;">
                {{ $t('Quotation.Expiration') }}:
              </div>
              <div style="display: inline;">
                {{ Form.quot_dd }}
              </div>
            </div>
            <div>
              <div style="display: inline;">
                納入期限:
              </div>
              <div style="display: inline;">
                受注後１週間以内
              </div>
            </div>
            <div>
              <div style="display: inline;">
                取引方法:
              </div>
              <div style="display: inline;">
                別途お打ち合わせ
              </div>
            </div>

            <div v-if="onlyJa()">
              <div>
                *すべての商品は税抜きです。
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- Right Column: Invoice Action -->
    <v-col cols="12" md="3">
      <v-card class="mb-6">
        <v-card-text>
          <div class="d-flex justify-end">
            <Language>
            </Language>
          </div>

          <v-btn color="primary" class="mb-3" block @click="printContract">
            Print
          </v-btn>
          <v-btn color="secondary" block outlined :to="{ name: 'quotation-list' }">
            OK
          </v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiSendOutline,
  mdiPlus,
  mdiPencil,
  mdiMinusCircle,
} from '@mdi/js'
import {
  mapActions, mapState, mapMutations,
} from 'vuex'
import {
  reactive,
} from '@vue/composition-api'
import printHtml from '@/utils/print'
import Language from '@/components/language.vue'

export default {
  components: {

    Language,
  },

  setup() {
    const invoiceForm = reactive({
      id: 5037,
      issuedDate: '',
      service: '',
      total: '',
      avatar: '',
      invoiceStatus: '',
      balance: '',
      dueDate: '',
      client: {
      },
      contract_details: [
        {
          detail_id: 1,
          product_id: 'ABC-111',
          pellet: 'pellet',
          ovv: '<p style="margin-bottom: 0px">750KG/F, 一共30包</p>',
          description: '',
          amount: 1000,
          price: 2000,
          totalPrice: 2000000,
        },
      ],

    })

    const printContract = () => {
      const printArea = document.getElementById('printArea').innerHTML
      printHtml(printArea)
    }

    const showContract = lang => {
      console.log('lang:', lang)
    }

    return {
      invoiceForm,

      // Icons
      icons: {
        mdiSendOutline,
        mdiPlus,
        mdiPencil,
        mdiMinusCircle,

      },

      printContract,
      showContract,
    }
  },
  data: () => ({
    Form: {
      item_ovv_ja: '',
      item_ovv_en: '',
      item_ovv_zh: '',
    },
    languageOvv: '',
    Selectedlanguage: '',
  }),
  computed: {
    ...mapState('quotationStore', ['selectedQuotation']),

    formatData() {
      return function (data) {
        if (typeof data === 'number') {
          return data.toString().replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }
        if (typeof data === 'string') {
          return data.replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, '$1,')
        }

        return data
      }
    },
    onlyJa() {
      return function () {
        if (localStorage.getItem('lang') === 'ja') {
          return true
        }

        return false
      }
    },
    totalEn() {
      return function () {
        if (localStorage.getItem('lang') === 'en') {
          return 'padding-right: 90px;'
        }

        return 'padding-right: 64px;'
      }
    },

    // Selectedlanguage() {
    //   let itemOvv = ''
    //   if (localStorage.getItem('lang') === 'zh') {
    //     console.log('ovv_cn')

    //     // itemOvv = Form.item_ovv_cn
    //     itemOvv = 'zh'
    //   }
    //   if (localStorage.getItem('lang') === 'ja') {
    //     console.log('ovv_ja')

    //     // itemOvv = Form.item_ovv_ja
    //     itemOvv = 'ja'
    //   }
    //   if (localStorage.getItem('lang') === 'en') {
    //     console.log('ovv_en')

    //     // itemOvv = Form.item_ovv_en
    //     itemOvv = 'en'
    //   }

    //   console.log('lang', itemOvv)

    //   return itemOvv
    // },

  },
  watch: {
    Selectedlanguage(val) {
      if (val === 'en') {
        this.languageOvv = this.Form.item_ovv_en
      }
      if (val === 'ja') {
        this.languageOvv = this.Form.item_ovv_ja
      }
      if (val === 'zh') {
        this.languageOvv = this.Form.item_ovv_cn
      }
    },
  },
  updated() {
    this.Selectedlanguage = localStorage.getItem('lang')
  },
  mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions('quotationStore', ['loadQuotation']),
    ...mapMutations('app', ['setOverlayStatus']),
    loadData() {
      this.setOverlayStatus(true)
      this.loadQuotation(this.$route.params.id).then(() => {
        this.Form = this.selectedQuotation
        console.log('Form', this.Form)
      })
        .finally(() => {
          console.log('selectedQuotation', this.selectedQuotation)
          this.setOverlayStatus(false)
        })
    },

    goBack() {

    },
  },
}
</script>

<style scoped>
#printArea {
  line-height: 1.5rem !important;
  width:794px;
  height:1090px;
}
.contractTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
}
.purchased-items-table th{
  background-color: transparent !important;
}
.purchased-items-table tr:last-child td{
  border-bottom: thin solid #E0E0E0;
}
.tableTotal {
  display: flex;
  flex-direction: row;
  margin: 24px 12px 0 12px;
  justify-content: end;
}
</style>
